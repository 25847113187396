

























//import { computed, defineComponent, onMounted, ref } from '@vue/composition-api'
import { computed, defineComponent, ref } from '@vue/composition-api'

//import ordersClient from '@/clients/ordersClient'

import OrderGrid from '@/components/OrderHistory/OrderGrid.vue'
import { OrderInfo } from '@/GeneratedTypes/Order/ListInfo/OrderInfo'
import { SectionNameConversion } from '@/views/Programs/Orders/vues/history/ext/SectionNames'
import AccordionItem from '@/components/AccordionItem.vue'
import Accordion from '@/components/Accordion.vue'
import Loading from '@/elements/Loading.vue'
//import store from '@/store'

interface ListSections {
  name: string
  orders: OrderInfo[]
}

export default defineComponent({
  name: 'OrderHistory',
  components: { Loading, AccordionItem, OrderGrid, Accordion },
  setup(props, ctx) {
    const loading = ref(false)
    const orders = ref<OrderInfo[] | null>(null)

    // onMounted(async () => {
    //   loading.value = true
    //   orders.value = await ordersClient.getOrderHistory({
    //     league: ctx.root.$route.params.id,
    //     account: store.getters.authorization.firstAccountNumber,
    //   })
    //   loading.value = false
    // })

    const sectionNames = computed(() =>
      (orders.value ?? []).map((x) => x.typeOrderID).filter((x, i, a) => i == a.indexOf(x))
    )

    const listSections = computed(() => {
      const sections = sectionNames.value.map(
        (x) =>
          ({
            name: SectionNameConversion[x ?? ''],
            orders: orders.value
              ?.filter((y) => y.typeOrderID == x)
              .sort((a, b) =>
                a.createDate?.valueOf() == b.createDate?.valueOf()
                  ? 0
                  : (a.createDate?.valueOf() ?? -1) > (b.createDate?.valueOf() ?? -2)
                  ? 1
                  : -1
              ),
          } as ListSections)
      )
      return sections
    })

    async function showOrder(o: OrderInfo) {
      await ctx.root.$router.push(`history/order-detail/${o.upwardOrderID}`)
    }

    return {
      orders,
      listSections,
      loading,
      showOrder,
    }
  },
})
